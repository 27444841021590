import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import landingButtonHover from "../../../../src/posts/yousign/images/landing-button-hover.gif";
import landingLinkHover from "../../../../src/posts/yousign/images/landing-link-hover.gif";
import screenReaderIssueMp4 from "../../../../src/posts/yousign/videos/screen-read-issue.mp4";
import screenReaderIssueWebm from "../../../../src/posts/yousign/videos/screen-read-issue.webm";
import * as React from 'react';
export default {
  GatsbyImage,
  getImage,
  landingButtonHover,
  landingLinkHover,
  screenReaderIssueMp4,
  screenReaderIssueWebm,
  React
};